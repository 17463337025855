import { Translate, NextLink } from '~/i18n';
import Container from '~/components/common/container';
const Component404 = () => {
   return (
      <div
         className="flex flex-1 h-full min-h-screen bg-center bg-cover"
         style={{ backgroundImage: 'url("/images/sand.jpg")' }}>
         <style jsx global>{`
            main {
               display: flex;
               flex-direction: column;
            }
         `}</style>
         <Container>
            <span className="block mt-32 font-extrabold text-center text-white text-9xl">404</span>
            <span className="block mt-2 text-3xl font-medium text-center text-white">
               <Translate id="common:404" />
            </span>
            <div className="text-center">
               <NextLink
                  href=""
                  className="inline-block w-48 py-2 mt-8 text-white border border-white rounded">
                  <Translate id="common:home" />
               </NextLink>
            </div>
         </Container>
      </div>
   );
};
export default Component404;
