import { useTranslate } from '~/i18n';
import { NextSeo } from 'next-seo';
import Component404 from '~/components/404';

const Page404 = () => {
   const { translate } = useTranslate();

   return (
      <div>
         <NextSeo title={translate({ id: 'common:404' })} nofollow noindex />
         <Component404 />
      </div>
   );
};

export default Page404;
